import { useIsMobile } from './useIsMobile';

const MOBILE_WIDTH = 640;
const DESKTOP_WIDTH = 1024;
const BRANDFOLDER_DOMAIN = 'cdn.bfldr.com';

export const useBrandfolderOptimizedImage = (url?:string, width?:number) => {
  if (!url || !url.includes(BRANDFOLDER_DOMAIN)) {
    return url;
  }

  const isMobile = useIsMobile();

  const imageUrl = url.replace(/&format=[a-z]+/, '');

  if (isMobile) {
    return `${imageUrl}&width=${width ?? MOBILE_WIDTH}`;
  }
  return `${imageUrl}&width=${width ?? DESKTOP_WIDTH}`;
};
